'use strict';

angular.module("systemMessages", ['common'])
    .factory('messageService', ["$http", "CommonService", function ($http, CommonService) {

        var promise = CommonService.getSystemMessages()
            .then(function (rtn) {

                var targets = {
                    "Aeros": [],
                    "Admin": [],
                    "Gemini": [],
                    "Mfg": [],
                    "aeRosLink": []
                };

                _.each(rtn.data.messages, function (message) {
                    _.each(message.target, function (target) {
                        targets[target].push(message);
                    });
                });

                function getMessagesByTarget(target) {
                    return targets[target];
                }

                return {
                    getMessagesByTarget: getMessagesByTarget
                };
            });

        function getMessages() {
            return promise;
        }

        return {
            getMessages: getMessages
        };
    }]);
